@import "../mixin";

.aboutme {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  &-section {
    display: flex;
    justify-content: center;
    color: $font-color;

    flex-direction: column;
    align-items: flex-start;
    width: 60%;
    height: 100%;
    font-weight: 200;

    &-title {
      font-size: 60px;
      letter-spacing: -4.5px;
      color: $font-highlight;
      font-weight: 700;
      white-space: nowrap;

      background: radial-gradient(circle, #fafafa, #000);
      background-clip: text;
      color: transparent;
      animation: gemini 5s linear infinite;

      text-shadow: 0px 0px 40px #fff;
      background-size: 200% 200%;
      user-select: none;
      transition: all 0.4s linear;

      &:hover {
        color: #fafafa;
      }
    }

    &-description {
      letter-spacing: -1.5px;
      line-height: 2;
      user-select: none;
    }

    &-skills {
      width: 80%;
      overflow: hidden;
      user-select: none;

      &-headline {
        letter-spacing: 1px;
        line-height: 1;
        font-size: 1.5em;
        font-weight: 500;
      }

      &-box {
        display: flex;
        align-items: center;
        opacity: 90%;
        background-color: transparent;
        border-radius: 12px;
        box-shadow: rgba(237, 218, 218, 0.2) 0px 30px 60px 40px inset,
          rgba(254, 203, 126, 0.55) 0px 18px 36px 12px inset,
          rgba(255, 255, 255, 1) 0px 0px 20px -8px;

        transition: all 0.5s linear;
        margin-top: 20px;

        &:hover {
          opacity: 100%;
        }

        &-items {
          display: flex;
          align-items: center;
          gap: 12px;
          font-size: 20px;
          position: relative;
          white-space: nowrap;
          animation: flowText 8s linear infinite;

          &.stop__animation {
            animation-play-state: paused;
          }
        }

        &-items p {
          margin: 20px;
        }
      }
    }
    &-right {
      width: 40%;
    }
  }
}

.hover-effect {
  display: inline-block;
  background: linear-gradient(90deg, #fff, #9b9b9b);
  background-size: 200%;
  background-position: 100%;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  transition: background-position 0.5s ease;
}

.hover-effect:hover {
  background-position: 0%;
}

@include mobile {
  .aboutme-section {
    &-title {
      font-size: 28px;
    }

    &-skills {
      height: 100%;
      &-box {
        height: 50px;

        &-items p {
          margin: 8px;
        }
      }
    }
  }

  .hover-effect {
    font-size: 14px;
    text-wrap: pretty;
  }

  img {
    width: 18px;
  }
}
