@import "../mixin";

.experience {
  width: 100%;
  height: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  &-section {
    display: flex;
    flex-direction: column;
    gap: 20px;

    height: 100%;
    width: 60%;
    color: $font-color;

    &-education {
      &-title {
        font-size: 48px;
        background: linear-gradient(270deg, rgb(254, 203, 126), #ffffff);
        background-clip: initial;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-shadow: 3px 3px 16px rgba(254, 203, 126, 0.8);
        user-select: none;
      }

      &-item {
        display: flex;
        flex-direction: column;
        gap: 8px;
        letter-spacing: -1px;
        &-info {
          font-weight: 300;
          &-academy {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
          }
        }
      }
    }
    &-work {
      &-title {
        font-size: 48px;
        background: linear-gradient(270deg, rgb(254, 203, 126), #ffffff);
        background-clip: initial;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-shadow: 3px 3px 16px rgba(254, 203, 126, 0.8);
        user-select: none;
      }

      &-item {
        display: flex;
        flex-direction: column;
        gap: 8px;
        letter-spacing: -1px;

        &-info {
          font-weight: 300;
          &-link {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
          }
        }
      }
    }
  }
}

.hover-effect {
  display: inline-block;
  background: linear-gradient(90deg, #fff, #9b9b9b);
  background-size: 200%;
  background-position: 100%;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  transition: background-position 0.5s ease;
  user-select: none;

  &.title {
    font-size: 24px;

    &-focus {
      color: #fff;
      text-align: center;
      -webkit-animation: glow 3s ease-in-out infinite alternate;
      -moz-animation: glow 3s ease-in-out infinite alternate;
      animation: glow 3s ease-in-out infinite alternate;
    }
  }

  &.link {
    display: inline-block;
    background: linear-gradient(270deg, rgb(254, 203, 126), #fff);
    background-size: 200%;
    background-position: 100%;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    transition: background-position 0.5s ease;
  }
}

.hover-effect:hover {
  background-position: 0%;
}

.mobile__description {
  font-size: 8px;
}

@include mobile {
  .experience {
    height: auto;

    &-section-work-title {
      font-size: 34px;
    }
    &-section-education-title {
      font-size: 34px;
    }
  }

  .hover-effect {
    &.title {
      font-size: 18px;
      &-focus {
        font-size: 18px;
      }
    }
    &.link {
      font-size: 12px;
    }
  }
}
