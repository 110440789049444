@import "../mixin";

.project {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  &-section {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    height: 100%;
    width: 80%;
    color: $font-color;

    &-project {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;

      &-title {
        font-size: 32px;
        color: $font-highlight;
        letter-spacing: 1.5px;
        display: flex;
        justify-content: center;
      }

      &-image {
        width: 100%;
        max-width: 300px;

        height: 300px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
        transition: all 0.4s linear;

        display: block;

        cursor: pointer;
        object-fit: cover;
        filter: drop-shadow(5px 5px 100px rgba(254, 203, 126, 0.6));

        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
}

@include mobile {
  .project-section {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 40px;

    &-project {
      display: flex;
      align-items: center;

      &-image {
        height: auto;
      }
    }
  }
}
