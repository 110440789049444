@import "../mixin";

.contact {
  width: 100%;
  height: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  &-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 52px;

    height: 100%;
    width: fit-content;
    color: $font-color;

    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
    }
  }
}

@include mobile {
  a {
    font-size: 14px;
  }
}
