@import "../mixin";

.header {
  position: fixed;
  height: 5%;
  width: 100%;
  z-index: 1;

  display: flex;
  justify-content: center;
  padding-top: 5%;

  &.mobile {
    background-color: #000;
    padding: 2%;
    opacity: 75%;
  }

  &-menu {
    display: flex;
    justify-content: space-between;

    color: #fafafa;

    gap: 40px;

    &-label {
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;
      height: 100%;
      letter-spacing: 2.5px;
      transition: transform 0.3s ease;
      cursor: pointer;

      &.active {
        text-decoration: underline;
        text-underline-offset: 10px;
      }

      &:hover {
        transform: scale(1.1) skew(-10deg);
      }
    }
  }
}

@include mobile {
  .header {
    font-size: 12px;

    &-menu {
      gap: 20px;
    }
  }
}
