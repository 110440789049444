.modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);

  opacity: 0;
  transform: scale(0.9);
  animation: modal-fade-in 0.3s forwards;

  &-frame {
    width: 300px;
    height: 400px;
    color: black;
    background-color: #81689d;

    border-radius: 12px;

    display: flex;
    flex-direction: column;
    align-items: center;

    opacity: 90%;

    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);

    &-content {
      width: 80%;
      color: #f0f0f0;
      line-height: 3;

      &-title {
        display: flex;
        justify-content: center;
        font-size: 20px;
        letter-spacing: 1.5px;
      }

      &-item {
        font-size: 15px;
        display: flex;
        flex-direction: column;
        line-height: 1.5;
      }
    }
  }
}
