// background variables
$background-color: #000;

// font variables
$font-family: "Helvetica Neue", Arial, sans-serif;
$font-size: 16px;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-color: #fafafa;

$font-highlight: #e0b0ff;

// Breakpoints
$breakpoint-mobile: 320px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
